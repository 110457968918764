var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Pending")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalPendingCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Pending 1")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalPending1Count))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Pending 2")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalPending2Count))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Processing")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalProcessingCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Packing")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalPackagingCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Shipping")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalShippingCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("On The Way")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalOnTheWayCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("In Review")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalInReviewCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Cancel")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalCancelCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Returned")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalReturnedCount))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Delivered")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.totalDeliveredCount))])], 1)]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }