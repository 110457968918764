var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center"
  }, [_c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "variant": ("light-" + (_vm.color)),
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": _vm.icon
    }
  })], 1), _c('div', {
    staticClass: "truncate"
  }, [_c('h2', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.statistic) + " ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary",
      "block": "",
      "href": _vm.to
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonName) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }