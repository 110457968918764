export const DASHBOARD_ACCESS = 'dashboard_access'
// Roles
export const ROLES_ACCESS = 'roles_access'
export const ROLES_CREATE = 'roles_create'
export const ROLES_SHOW = 'roles_show'
export const ROLES_EDIT = 'roles_edit'
export const ROLES_DELETE = 'roles_delete'

// Users
export const USERS_ACCESS = 'users_access'
export const USERS_CREATE = 'users_create'
export const USERS_SHOW = 'users_show'
export const USERS_EDIT = 'users_edit'
export const USERS_DELETE = 'users_delete'

// Parent Categories
export const CATEGORIES_ACCESS = 'categories_access'
export const CATEGORIES_CREATE = 'categories_create'
export const CATEGORIES_SHOW = 'categories_show'
export const CATEGORIES_EDIT = 'categories_edit'
export const CATEGORIES_DELETE = 'categories_delete'

// Sub Categories
export const SUB_CATEGORIES_ACCESS = 'sub_categories_access'
export const SUB_CATEGORIES_CREATE = 'sub_categories_create'
export const SUB_CATEGORIES_SHOW = 'sub_categories_show'
export const SUB_CATEGORIES_EDIT = 'sub_categories_edit'
export const SUB_CATEGORIES_DELETE = 'sub_categories_delete'

// Child Categories
export const CHILD_CATEGORIES_ACCESS = 'child_categories_access'
export const CHILD_CATEGORIES_CREATE = 'child_categories_create'
export const CHILD_CATEGORIES_SHOW = 'child_categories_show'
export const CHILD_CATEGORIES_EDIT = 'child_categories_edit'
export const CHILD_CATEGORIES_DELETE = 'child_categories_delete'

// Products
export const PRODUCTS_ACCESS = 'products_access'
export const PRODUCTS_CREATE = 'products_create'
export const PRODUCTS_SHOW = 'products_show'
export const PRODUCTS_EDIT = 'products_edit'
export const PRODUCTS_DELETE = 'products_delete'

// Customer
export const CUSTOMERS_ACCESS = 'customers_access'
export const CUSTOMERS_SHOW = 'customers_show'
export const CUSTOMERS_BLOCK = 'customers_block'

// Orders
export const ORDERS_ACCESS = 'orders_access'
export const ORDERS_SHOW = 'orders_show'
export const ORDERS_STATUS_CHANGE = 'orders_status_change'
export const ORDERS_FOLLOW_UP_NAME_SHOW = 'orders_follow_up_name_show'

// Fb Pixel
export const FB_PIXEL_ACCESS = 'fb_pixel_access'
export const FB_PIXEL_CREATE = 'fb_pixel_create'
export const FB_PIXEL_DELETE = 'fb_pixel_delete'

// Master Settings
export const MASTER_SETTINGS_ACCESS = 'master_settings_access'
export const DELIVERY_CHARGE_EDIT = 'delivery_charge_edit'
