var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "title": _vm.roleName
    }
  }, [_c('div', [_c('vue-good-table', {
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (props) {
        var _props$row, _props$row2, _props$row2$permissio, _props$row3, _props$row4, _props$row4$permissio, _props$row5, _props$row6, _props$row6$permissio, _props$row7, _props$row8, _props$row8$permissio, _props$row9, _props$row10, _props$row10$permissi, _props$row11, _props$row12, _props$row12$permissi;
        return [props.column.field === 'name' ? _c('div', [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(props.row.name))])]) : props.column.field === 'access' ? _c('div', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.permissions ? [_vm._l((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$permissio = _props$row2.permissions) === null || _props$row2$permissio === void 0 ? void 0 : _props$row2$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'access' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function (isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'create' ? _c('div', [(_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.permissions ? [_vm._l((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : (_props$row4$permissio = _props$row4.permissions) === null || _props$row4$permissio === void 0 ? void 0 : _props$row4$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'create' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function (isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'show' ? _c('div', [(_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.permissions ? [_vm._l((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$permissio = _props$row6.permissions) === null || _props$row6$permissio === void 0 ? void 0 : _props$row6$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'show' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function (isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'edit' ? _c('div', [(_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.permissions ? [_vm._l((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : (_props$row8$permissio = _props$row8.permissions) === null || _props$row8$permissio === void 0 ? void 0 : _props$row8$permissio.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'edit' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function (isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'delete' ? _c('div', [(_props$row9 = props.row) !== null && _props$row9 !== void 0 && _props$row9.permissions ? [_vm._l((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : (_props$row10$permissi = _props$row10.permissions) === null || _props$row10$permissi === void 0 ? void 0 : _props$row10$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'delete' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function (isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          })] : _vm._e()];
        })] : _vm._e()], 2) : props.column.field === 'others' ? _c('div', [(_props$row11 = props.row) !== null && _props$row11 !== void 0 && _props$row11.permissions ? [_vm._l((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$permissi = _props$row12.permissions) === null || _props$row12$permissi === void 0 ? void 0 : _props$row12$permissi.data, function (item) {
          return [(item === null || item === void 0 ? void 0 : item.group) == 'others' ? [_c('b-form-checkbox', {
            key: item === null || item === void 0 ? void 0 : item.id,
            attrs: {
              "checked": _vm.roleHasPermissions.includes(item === null || item === void 0 ? void 0 : item.id),
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function (isChecked) {
                return _vm.onChangeSwitch(isChecked, item.id);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")(item === null || item === void 0 ? void 0 : item.name)) + " ")])] : _vm._e()];
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }