var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function ($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  }), _vm.$permissionAbility(_vm.PRODUCTS_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Add ")])] : _vm._e()], 2)]), [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', {
    staticStyle: {
      "overflow-x": "auto",
      "white-space": "nowrap",
      "max-width": "100%"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "btn-radios-1",
      "button-variant": "outline-primary",
      "options": _vm.optionsRadio,
      "buttons": "",
      "name": "radios-btn-default"
    },
    model: {
      value: _vm.filterStatus,
      callback: function ($$v) {
        _vm.filterStatus = $$v;
      },
      expression: "filterStatus"
    }
  })], 1)], 1)])], 1)], _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "style-class": "vgt-table table-custom-style bordered condensed",
      "line-numbers": true,
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function ($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function ($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (props) {
        var _props$row, _props$row2, _props$row2$large_pic, _props$row3, _props$row4, _props$row5, _props$column, _props$column2, _props$row7, _props$row8, _props$column3, _props$row9, _props$row10, _props$column4, _props$row11, _props$column5, _props$row12, _props$column6, _props$row13, _props$column7, _props$row14;
        return [props.column.field === 'name_format' ? _c('span', [props !== null && props !== void 0 && (_props$row = props.row) !== null && _props$row !== void 0 && _props$row.large_pictures ? _c('b-avatar', {
          staticClass: "mr-1 avatar-large",
          attrs: {
            "src": props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$large_pic = _props$row2.large_pictures[0]) === null || _props$row2$large_pic === void 0 ? void 0 : _props$row2$large_pic.original_url
          }
        }) : _vm._e(), _c('b-link', {
          attrs: {
            "href": _vm.showProductDetail((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.slug).url,
            "target": _vm.showProductDetail((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.slug).target
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.truncateName((_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.name)))])])], 1) : _vm._e(), props.column.field === 'format_status' ? _c('span', [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-warning"
          },
          on: {
            "click": function ($event) {
              var _props$row6;
              return _vm.changeStatus((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props.row.status ? 'Active' : 'Inactive') + " ")])], 1) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_description' ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.showDescriptionModal(props.row);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_price' && _vm.$permissionAbility(_vm.PRODUCTS_EDIT, _vm.permissions) ? [_c('div', [_c('b-link', {
          on: {
            "click": function ($event) {
              return _vm.onShowTwo(props.row);
            }
          }
        }, [_c('span', [_c('b', [_vm._v("Regular Price:")]), _vm._v(" " + _vm._s((_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.price))])])], 1), _c('div', [_c('span', [_c('b', [_vm._v("Sale Price:")]), _vm._v(" " + _vm._s((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.sale_price))])])] : (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_price' ? [_c('div', [_c('span', [_c('b', [_vm._v("Regular Price:")]), _vm._v(" " + _vm._s((_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.price))])]), _c('div', [_c('span', [_c('b', [_vm._v("Sale Price:")]), _vm._v(" " + _vm._s((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.sale_price))])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_priority' && _vm.$permissionAbility(_vm.PRODUCTS_EDIT, _vm.permissions) ? [_c('div', [_c('b-link', {
          on: {
            "click": function ($event) {
              return _vm.onShowTwo(props.row);
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.priority))])])], 1)] : (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_priority' ? [_c('div', [_c('span', [_vm._v(" " + _vm._s((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.priority))])])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'format_stock' && _vm.$permissionAbility(_vm.PRODUCTS_EDIT, _vm.permissions) ? [_c('div', [_c('b-link', {
          on: {
            "click": function ($event) {
              return _vm.onShowTwo(props.row);
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.stock))])])], 1)] : (props === null || props === void 0 ? void 0 : (_props$column7 = props.column) === null || _props$column7 === void 0 ? void 0 : _props$column7.field) === 'format_stock' ? [_c('div', [_c('span', [_vm._v(" " + _vm._s((_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.stock))])])] : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.PRODUCTS_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Show/ Edit Details")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.PRODUCTS_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e(), [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.onCopy(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Copy")])], 1)]], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function (props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20', '50', '100', '200', '300']
          },
          on: {
            "input": function (value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function ($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function (value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 2), _c('b-modal', {
    attrs: {
      "id": "modal-product-form",
      "centered": "",
      "title": _vm.modalType == 'editModal' ? 'Edit Product' : 'Add Product',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "validationRef"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_vm.modalType == 'editModal' && _vm.previewImageArray.length > 0 && !_vm.removeUploadImage ? _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("Uploaded Images")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1 mb-1",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.removeUploadedImage
    }
  }, [_vm._v(" Remove All ")]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "overflow-x": "auto"
    }
  }, _vm._l(_vm.previewImageArray, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "image-container"
    }, [_c('img', {
      staticClass: "mr-1",
      attrs: {
        "src": image.preview,
        "alt": "Preview"
      }
    })]);
  }), 0)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', [_vm._v("New Images")]), _vm.imageArray.length > 0 ? _c('div', {
    staticClass: "d-flex justify-content-center",
    staticStyle: {
      "overflow-x": "auto"
    }
  }, _vm._l(_vm.imageArray, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "image-container"
    }, [_c('img', {
      staticClass: "mr-1",
      attrs: {
        "src": image.preview,
        "alt": "Preview"
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('img', {
    attrs: {
      "src": _vm.previewImage,
      "alt": "Preview"
    }
  })])]), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Priority",
      "label-for": "priority"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "priority",
      "vid": "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "priority",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "priority",
            "placeholder": "Product Priority"
          },
          model: {
            value: _vm.priorityNumber,
            callback: function ($$v) {
              _vm.priorityNumber = $$v;
            },
            expression: "priorityNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Image",
      "label-for": "large_pictures"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "large_pictures",
      "vid": "large_pictures"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-file', {
          staticClass: "mr-1",
          attrs: {
            "id": "large_pictures",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose an image or drop it here...",
            "drop-placeholder": "Drop image here... ",
            "accept": "image/*"
          },
          on: {
            "change": _vm.loadImage
          },
          model: {
            value: _vm.image,
            callback: function ($$v) {
              _vm.image = $$v;
            },
            expression: "image"
          }
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": _vm.removeImage
          }
        }, [_vm._v(" Remove ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "name",
            "placeholder": "Product Name"
          },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status",
      "vid": "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "id": "status",
            "options": _vm.statusValueOption,
            "reduce": function (option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.status,
            callback: function ($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Video Link",
      "label-for": "video_link"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "video_link",
      "vid": "video_link"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "video_link",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "video_link",
            "placeholder": "Product video link"
          },
          model: {
            value: _vm.videoLink,
            callback: function ($$v) {
              _vm.videoLink = $$v;
            },
            expression: "videoLink"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Short Description",
      "label-for": "description"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "short_description",
      "vid": "short_description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('quill-editor', {
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function ($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v("Italic")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v("Underline")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v("Strike")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        })])])]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category ",
      "label-for": "category_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "category_id",
      "vid": "category_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('v-select', {
          attrs: {
            "id": "category_id",
            "options": _vm.categoryOptions,
            "reduce": function (option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Category"
          },
          on: {
            "input": _vm.filterSubCategories
          },
          model: {
            value: _vm.categoryId,
            callback: function ($$v) {
              _vm.categoryId = $$v;
            },
            expression: "categoryId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sub Category ",
      "label-for": "sub_category_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sub_category_id",
      "vid": "sub_category_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          attrs: {
            "id": "sub_category_id",
            "options": _vm.filteredSubCategoryOptions,
            "reduce": function (option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Sub Category"
          },
          on: {
            "input": _vm.filterChildCategories
          },
          model: {
            value: _vm.subCategoryId,
            callback: function ($$v) {
              _vm.subCategoryId = $$v;
            },
            expression: "subCategoryId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Child Category ",
      "label-for": "child_category_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "child_category_id",
      "vid": "child_category_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "id": "child_category_id",
            "options": _vm.filteredChildCategoryOptions,
            "reduce": function (option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose Child Category"
          },
          model: {
            value: _vm.childCategoryId,
            callback: function ($$v) {
              _vm.childCategoryId = $$v;
            },
            expression: "childCategoryId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "SKU ",
      "label-for": "sku_code"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sku",
      "vid": "sku"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "sku",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "sku",
            "placeholder": "Product SKU Code"
          },
          model: {
            value: _vm.skuCode,
            callback: function ($$v) {
              _vm.skuCode = $$v;
            },
            expression: "skuCode"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Stock ",
      "label-for": "stock"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "stock",
      "vid": "stock"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "stock",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "stock",
            "placeholder": "Product Stock"
          },
          on: {
            "wheel": function ($event) {
              $event.preventDefault();
            }
          },
          model: {
            value: _vm.quantity,
            callback: function ($$v) {
              _vm.quantity = $$v;
            },
            expression: "quantity"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Price ",
      "label-for": "price"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "price",
      "vid": "price"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "price",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "price",
            "placeholder": "Product Regular Price"
          },
          on: {
            "wheel": function ($event) {
              $event.preventDefault();
            }
          },
          model: {
            value: _vm.price,
            callback: function ($$v) {
              _vm.price = $$v;
            },
            expression: "price"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sale Price ",
      "label-for": "sale_price"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sale_price",
      "vid": "sale_price"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "sale_price",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "sale_price",
            "placeholder": "Product Sale Price"
          },
          model: {
            value: _vm.salePrice,
            callback: function ($$v) {
              _vm.salePrice = $$v;
            },
            expression: "salePrice"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label",
      "label-for": "labels"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "labels",
      "vid": "labels"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref14) {
        var errors = _ref14.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "id": "labels",
            "placeholder": "Add Label",
            "options": _vm.labelOptions,
            "multiple": "",
            "taggable": "",
            "push-tags": ""
          },
          model: {
            value: _vm.selectLabels,
            callback: function ($$v) {
              _vm.selectLabels = $$v;
            },
            expression: "selectLabels"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Flash Sale",
      "label-for": "is_sale"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "is_sale",
      "vid": "is_sale"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "id": "is_sale",
            "options": _vm.statusValueOption,
            "reduce": function (option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.isSale,
            callback: function ($$v) {
              _vm.isSale = $$v;
            },
            expression: "isSale"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "New Arrival",
      "label-for": "is_new"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "is_new",
      "vid": "is_new"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref16) {
        var errors = _ref16.errors;
        return [_c('v-select', {
          attrs: {
            "id": "is_new",
            "options": _vm.statusValueOption,
            "reduce": function (option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.isNew,
            callback: function ($$v) {
              _vm.isNew = $$v;
            },
            expression: "isNew"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hot Deal",
      "label-for": "is_hot"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "is_hot",
      "vid": "is_hot"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var errors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "is_hot",
            "options": _vm.statusValueOption,
            "reduce": function (option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.isHot,
            callback: function ($$v) {
              _vm.isHot = $$v;
            },
            expression: "isHot"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "For You",
      "label-for": "is_for_you"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "is_for_you",
      "vid": "is_for_you"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var errors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "is_for_you",
            "options": _vm.statusValueOption,
            "reduce": function (option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.isForYou,
            callback: function ($$v) {
              _vm.isForYou = $$v;
            },
            expression: "isForYou"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.modalType == 'editModal' ? 'Update' : 'Create') + " ")])], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "description-modal",
      "centered": "",
      "title": "Description",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenDescriptionModal
    }
  }, [_c('b-card-text', [_c('h6', [_vm._v("Product Description")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.description)
    }
  }), _c('h6', [_vm._v("Offer Notice")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.offerNotice)
    }
  })])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-product-form-two",
      "centered": "",
      "title": _vm.EditProductTwo,
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "hidden": _vm.hiddenModalTwo
    }
  }, [_c('validation-observer', {
    ref: "validationRefTwo"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.validationFormTwo($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Priority",
      "label-for": "priority"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "priority",
      "vid": "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "priority",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "priority",
            "placeholder": "Product Priority"
          },
          model: {
            value: _vm.priorityNumber,
            callback: function ($$v) {
              _vm.priorityNumber = $$v;
            },
            expression: "priorityNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Stock ",
      "label-for": "stock"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "stock",
      "vid": "stock"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref20) {
        var errors = _ref20.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "stock",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "stock",
            "placeholder": "Product Stock"
          },
          on: {
            "wheel": function ($event) {
              $event.preventDefault();
            }
          },
          model: {
            value: _vm.quantity,
            callback: function ($$v) {
              _vm.quantity = $$v;
            },
            expression: "quantity"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Price ",
      "label-for": "price"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "price",
      "vid": "price"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref21) {
        var errors = _ref21.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "price",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "price",
            "placeholder": "Product Regular Price"
          },
          on: {
            "wheel": function ($event) {
              $event.preventDefault();
            }
          },
          model: {
            value: _vm.price,
            callback: function ($$v) {
              _vm.price = $$v;
            },
            expression: "price"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s('Update') + " ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }