var render = function () {
  var _vm$customerData, _vm$customerData$cust, _vm$customerData2, _vm$customerData2$cus, _vm$orderData, _vm$orderData2, _vm$orderData3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-col', {
    attrs: {
      "lg": "6",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Customer Name")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$customerData = _vm.customerData) === null || _vm$customerData === void 0 ? void 0 : (_vm$customerData$cust = _vm$customerData.customer) === null || _vm$customerData$cust === void 0 ? void 0 : _vm$customerData$cust.full_name))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Phone")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$customerData2 = _vm.customerData) === null || _vm$customerData2 === void 0 ? void 0 : (_vm$customerData2$cus = _vm$customerData2.customer) === null || _vm$customerData2$cus === void 0 ? void 0 : _vm$customerData2$cus.phone))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Address")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$orderData = _vm.orderData) === null || _vm$orderData === void 0 ? void 0 : _vm$orderData.detail_address))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Order Status")]), [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.statusFormat((_vm$orderData2 = _vm.orderData) === null || _vm$orderData2 === void 0 ? void 0 : _vm$orderData2.status)) + " ")])]], 2)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "4"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Order Note")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$orderData3 = _vm.orderData) === null || _vm$orderData3 === void 0 ? void 0 : _vm$orderData3.order_notes))])], 1)]], 2)], 1)], 1)], 1), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "line-numbers": true,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function ($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function ($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (props) {
        return [props.column.field === 'name' ? _c('span', [_vm._v(" name ")]) : _vm._e(), props.column.field === 'format_quantity' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(props.row.pivot.quantity) + " ")])], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [[_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.onView(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("View")])], 1)]], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function (props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function (value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function ($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function (value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }