var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "custom-search align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Search...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function ($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)])]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Date Filter",
      "config": {
        mode: 'range'
      }
    },
    model: {
      value: _vm.rangeDate,
      callback: function ($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v(" Clear ")])], 1)], 1), [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', [_c('b-badge', {
    staticStyle: {
      "font-size": "xx-large"
    },
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.statusCount) + " ")])], 1)]), _vm.isRowChecked && _vm.$permissionAbility(_vm.ORDERS_STATUS_CHANGE, _vm.permissions) ? [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "id": "dropdown-1",
      "text": "Action",
      "variant": "primary"
    }
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.printSelectedSticker();
      }
    }
  }, [_vm._v("Print Sticker")]), _c('b-dropdown-item', {
    attrs: {
      "value": "pending"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('pending');
      }
    }
  }, [_vm._v("Mark as Pending")]), _c('b-dropdown-item', {
    attrs: {
      "value": "pending-1"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('pending-1');
      }
    }
  }, [_vm._v("Mark as Pending 1")]), _c('b-dropdown-item', {
    attrs: {
      "value": "pending-2"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('pending-2');
      }
    }
  }, [_vm._v("Mark as Pending 2")]), _c('b-dropdown-item', {
    attrs: {
      "value": "processing"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('processing');
      }
    }
  }, [_vm._v("Mark as Processing")]), _c('b-dropdown-item', {
    attrs: {
      "value": "packing"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('packing');
      }
    }
  }, [_vm._v("Mark as Packing")]), _c('b-dropdown-item', {
    attrs: {
      "value": "shipping"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('shipping');
      }
    }
  }, [_vm._v("Mark as Shipping")]), _c('b-dropdown-item', {
    attrs: {
      "value": "on_the_way"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('on_the_way');
      }
    }
  }, [_vm._v("Mark as On the way")]), _c('b-dropdown-item', {
    attrs: {
      "value": "in_review"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('in_review');
      }
    }
  }, [_vm._v("Mark as In Review")]), _c('b-dropdown-item', {
    attrs: {
      "value": "rejected"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('rejected');
      }
    }
  }, [_vm._v("Mark as Rejected")]), _c('b-dropdown-item', {
    attrs: {
      "value": "returned"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('returned');
      }
    }
  }, [_vm._v("Mark as Returned")]), _c('b-dropdown-item', {
    attrs: {
      "value": "canceled"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('canceled');
      }
    }
  }, [_vm._v("Mark as Canceled")]), _c('b-dropdown-item', {
    attrs: {
      "value": "delivered"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSelectedStatus('delivered');
      }
    }
  }, [_vm._v("Mark as Delivered")])], 1)], 1)] : _vm._e(), _c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', {
    staticStyle: {
      "overflow-x": "auto",
      "white-space": "nowrap",
      "max-width": "100%"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "btn-radios-1",
      "button-variant": "outline-primary",
      "options": _vm.optionsRadio,
      "buttons": "",
      "name": "radios-btn-default"
    },
    model: {
      value: _vm.filterStatus,
      callback: function ($$v) {
        _vm.filterStatus = $$v;
      },
      expression: "filterStatus"
    }
  })], 1)], 1)])], 2)], _c('div', {
    staticStyle: {
      "max-height": "600px",
      "overflow-y": "auto"
    }
  }, [_c('vue-good-table', {
    attrs: {
      "line-numbers": true,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "select-options": {
        enabled: true,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }
    },
    on: {
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function ($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function ($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (props) {
        return [props.column.field === 'format_order' ? _c('span', [_c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_vm.$permissionAbility(_vm.ORDERS_SHOW, _vm.permissions) ? [_c('b-link', {
          on: {
            "click": function ($event) {
              return _vm.showModal(props.row.id);
            }
          }
        }, [props.row.is_sticker_printed == 'yes' ? _c('span', {
          staticStyle: {
            "font-size": "small"
          }
        }, [_c('svg', {
          staticStyle: {
            "margin-bottom": "5px"
          },
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "x": "0px",
            "y": "0px",
            "width": "20",
            "height": "20",
            "viewBox": "0 0 48 48"
          }
        }, [_c('path', {
          attrs: {
            "fill": "#43A047",
            "d": "M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"
          }
        })])]) : _vm._e(), _c('strong', [_vm._v("ID: ")]), _c('span', [_vm._v(_vm._s(props.row.large_order_id))]), props.row.comment ? _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v("*")]) : _vm._e()])] : [props.row.is_sticker_printed == 'yes' ? _c('span', {
          staticStyle: {
            "font-size": "small"
          }
        }, [_c('svg', {
          staticStyle: {
            "margin-bottom": "5px"
          },
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "x": "0px",
            "y": "0px",
            "width": "20",
            "height": "20",
            "viewBox": "0 0 48 48"
          }
        }, [_c('path', {
          attrs: {
            "fill": "#43A047",
            "d": "M40.6 12.1L17 35.7 7.4 26.1 4.6 29 17 41.3 43.4 14.9z"
          }
        })])]) : _vm._e(), _c('strong', [_vm._v("ID: ")]), _c('span', [_vm._v(_vm._s(props.row.large_order_id))]), props.row.comment ? _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v("*")]) : _vm._e()]], 2), _c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_c('strong', [_vm._v("Tk: ")]), _c('span', [_vm._v(_vm._s(props.row.total_price))])])]) : _vm._e(), props.column.field === 'format_customer' ? _c('span', [_c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_c('strong', [_vm._v("Name: ")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(props.row.customer.first_name))]), _vm._v(" "), props.row.comment ? _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v("*")]) : _vm._e()]), _c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_c('strong', [_vm._v("Phone: ")]), props.row.customer.phone && props.row.customer.phone.startsWith('+880') ? _c('span', [_c('b-link', {
          attrs: {
            "href": 'https://wa.me/' + props.row.customer.phone,
            "target": "_blank"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props.row.customer.phone) + " ")])], 1)], 1) : props.row.customer.phone && props.row.customer.phone.startsWith('880') ? _c('span', [_c('b-link', {
          attrs: {
            "href": 'https://wa.me/+' + props.row.customer.phone,
            "target": "_blank"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props.row.customer.phone) + " ")])], 1)], 1) : _c('span', [_c('b-link', {
          attrs: {
            "href": 'https://wa.me/+88' + props.row.customer.phone,
            "target": "_blank"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props.row.customer.phone) + " ")])], 1)], 1), props.row.comment ? _c('span', {
          staticStyle: {
            "color": "red"
          }
        }, [_vm._v("*")]) : _vm._e()])]) : _vm._e(), props.column.field === 'format_status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.statusFormat(props.row.status)) + " ")])], 1) : _vm._e(), props.column.field === 'format_payment_method' ? _c('span', [props.row.payment_method == 'cash_on_delivery' ? [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" COD ")])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_delivery_charge' ? _c('span', [_c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_c('span', [_vm._v("Product Price: " + _vm._s(props.row.total_price - props.row.delivery_charge) + "Tk")])]), _c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_c('span', [_c('strong', [_vm._v(_vm._s(props.row.order_from))])])]), _c('div', {
          staticStyle: {
            "display": "block"
          }
        }, [_vm._v(" Tk: " + _vm._s(props.row.delivery_charge) + " ")])]) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [[_vm.$permissionAbility(_vm.ORDERS_SHOW, _vm.permissions) ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.onView(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("View")])], 1) : _vm._e(), _vm.$permissionAbility(_vm.ORDERS_SHOW, _vm.permissions) ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.showModal(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("View Here")])], 1) : _vm._e(), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.showOrderCommentModal(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Followup")])], 1)]], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function (props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20', '50', '80', '100', '200', '300']
          },
          on: {
            "input": function (value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function ($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function (value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)], 2), _c('b-modal', {
    attrs: {
      "id": "modal-order-details",
      "centered": "",
      "size": "xl",
      "title": "Order Details",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_vm.modalOrderId ? _c('h3', [_vm._v("Order Id: " + _vm._s(_vm.modalOrderId))]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "10"
    }
  }, [_c('h4', {
    staticClass: "text-capitalize"
  }, [_vm._v(" Status: "), _c('b-badge', {
    staticStyle: {
      "font-size": "large"
    },
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.statusFormat(_vm.modalOrderStatus)) + " ")])], 1)])], 1), _c('hr'), _vm.modalCustomerId ? [_c('customer-all-order-status-vue', {
    attrs: {
      "customerId": _vm.modalCustomerId
    }
  })] : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Customer")]), [_c('div', [_c('b-card-text', [_c('strong', [_c('b-link', {
    on: {
      "click": function ($event) {
        return _vm.onViewCustomer(_vm.modalCustomerId);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.modalCustomerName) + " ")]), _vm.modalCustomerStatus ? _c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Banned ")]) : _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Active ")])], 1)])], 1), _c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.modalCustomerPhone))])], 1), _c('div', [_c('b-card-text', [_vm._v(_vm._s(_vm.modalCustomerAddress))])], 1)], _vm.$permissionAbility(_vm.CUSTOMERS_BLOCK, _vm.permissions) ? [_c('br'), _c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Customer Status Change")]), _c('div', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.bandActiveCustomer
    }
  }, [_vm.modalCustomerStatus ? [_vm._v(" Active ")] : [_vm._v(" Banned ")]], 2)], 1)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "8"
    }
  }, _vm._l(_vm.modalProducts, function (item, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "3"
      }
    }, [_c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v("Product name")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(item.name))])], 1)]], 2), _c('b-col', {
      attrs: {
        "md": "2",
        "lg": "2",
        "xs": "2"
      }
    }, [_c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v("SKU")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(item.sku))])], 1)]], 2), _c('b-col', {
      attrs: {
        "md": "2",
        "lg": "2",
        "xs": "2"
      }
    }, [_c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v("Quantity")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(item.pivot.quantity))])], 1)]], 2), _c('b-col', {
      attrs: {
        "md": "2",
        "lg": "2",
        "xs": "2"
      }
    }, [_c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v("Image")]), _c('div', {
      staticClass: "image-container"
    }, [_c('img', {
      staticClass: "small-image",
      attrs: {
        "src": item.small_pictures[0].original_url
      }
    }), _c('div', {
      staticClass: "large-image",
      style: {
        backgroundImage: 'url(' + item.small_pictures[0].original_url + ')'
      }
    })])])], 1);
  }), 1)], 1)], 2), _c('b-modal', {
    attrs: {
      "id": "order-comment-modal",
      "centered": "",
      "size": "lg",
      "title": "Followup",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenOrderCommentModal
    }
  }, [_c('div', {
    staticClass: "scrollable"
  }, [_vm._l(_vm.followUpMsgs, function (followUpMsg) {
    return _c('b-col', {
      key: followUpMsg.id,
      attrs: {
        "md": "12",
        "lg": "12",
        "xs": "12"
      }
    }, [_vm.$permissionAbility(_vm.ORDERS_FOLLOW_UP_NAME_SHOW, _vm.permissions) ? _c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(followUpMsg.user.name))]) : _vm._e(), _c('div', [_c('b-card-text', [_vm._v(_vm._s(followUpMsg.msg))])], 1), _c('h6', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(_vm.formatFnTableLastContactDate(followUpMsg.created_at)))]), _c('hr')]);
  }), _c('br'), _c('br'), _c('br'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "address",
      "type": "text",
      "placeholder": "Followup",
      "rows": "3"
    },
    model: {
      value: _vm.followupComment,
      callback: function ($$v) {
        _vm.followupComment = $$v;
      },
      expression: "followupComment"
    }
  })], 1), _c('b-col', {
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.submitFollowupComment
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 2)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }