var render = function () {
  var _vm$userInfo, _vm$userInfo2, _vm$userInfo3, _vm$userInfo3$roles, _vm$userInfo3$roles$d, _vm$userInfo4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("User Name")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$userInfo = _vm.userInfo) === null || _vm$userInfo === void 0 ? void 0 : _vm$userInfo.name))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("User Email")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$userInfo2 = _vm.userInfo) === null || _vm$userInfo2 === void 0 ? void 0 : _vm$userInfo2.email))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("User Roles")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s((_vm$userInfo3 = _vm.userInfo) === null || _vm$userInfo3 === void 0 ? void 0 : (_vm$userInfo3$roles = _vm$userInfo3.roles) === null || _vm$userInfo3$roles === void 0 ? void 0 : (_vm$userInfo3$roles$d = _vm$userInfo3$roles.data[0]) === null || _vm$userInfo3$roles$d === void 0 ? void 0 : _vm$userInfo3$roles$d.name))])], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "3"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("User Created On")]), [_c('div', [_c('b-card-text', [_vm._v(_vm._s(this.formatFnTableLastContactDate((_vm$userInfo4 = _vm.userInfo) === null || _vm$userInfo4 === void 0 ? void 0 : _vm$userInfo4.created_at)))])], 1)]], 2)], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Date Filter",
      "config": {
        mode: 'range'
      }
    },
    model: {
      value: _vm.rangeDate,
      callback: function ($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v(" Clear ")])], 1)], 1), _c('vue-good-table', {
    attrs: {
      "line-numbers": true,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function ($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function ($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (props) {
        var _props$row$order;
        return [props.column.field === 'format_order' ? _c('span', [_vm._v(" " + _vm._s((_props$row$order = props.row.order) === null || _props$row$order === void 0 ? void 0 : _props$row$order.large_order_id) + " ")]) : _vm._e()];
      }
    }, {
      key: "pagination-bottom",
      fn: function (props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function (value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function ($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function (value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }