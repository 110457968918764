var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [[_c('b-row', [_c('b-col', {
    attrs: {
      "md": "10"
    }
  }, [_c('div', {
    staticStyle: {
      "overflow-x": "auto",
      "white-space": "nowrap",
      "max-width": "100%"
    }
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "btn-radios-1",
      "button-variant": "outline-primary",
      "options": _vm.optionsRadio,
      "buttons": "",
      "name": "radios-btn-default"
    },
    model: {
      value: _vm.filterStatus,
      callback: function ($$v) {
        _vm.filterStatus = $$v;
      },
      expression: "filterStatus"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.exportDataToCsv
    }
  }, [_vm._v(" Export CSV ")])], 1)], 1)], _c('vue-good-table', {
    attrs: {
      "line-numbers": true,
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "select-options": {
        enabled: true,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }
    },
    on: {
      "on-selected-rows-change": _vm.selectionChanged,
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function ($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function ($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function (props) {
        return [props.column.field === 'name' ? _c('span', [_vm._v(" name ")]) : _vm._e(), props.column.field === 'format_is_band' ? _c('span', [_c('b-badge', [_vm._v(" " + _vm._s(props.row.is_band ? 'Inactive' : 'Active') + " ")])], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [[_vm.$permissionAbility(_vm.CUSTOMERS_SHOW, _vm.permissions) ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.onView(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("View")])], 1) : _vm._e(), _vm.$permissionAbility(_vm.CUSTOMERS_BLOCK, _vm.permissions) ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.bandActiveCustomer(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Change Status")])], 1) : _vm._e()]], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function (props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '15', '20']
          },
          on: {
            "input": function (value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function ($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function (value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }