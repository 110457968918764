var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Delivery Charge")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Inside & Outside Dhaka")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Inside Dhaka",
      "vid": "inside_dhaka",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "inside_dhaka",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Inside Dhaka"
          },
          model: {
            value: _vm.chargeInsideDhaka,
            callback: function ($$v) {
              _vm.chargeInsideDhaka = $$v;
            },
            expression: "chargeInsideDhaka"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Outside Dhaka",
      "vid": "outside_dhaka",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "outside_dhaka",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Outside Dhaka"
          },
          model: {
            value: _vm.chargeOutsideDhaka,
            callback: function ($$v) {
              _vm.chargeOutsideDhaka = $$v;
            },
            expression: "chargeOutsideDhaka"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.$permissionAbility(_vm.DELIVERY_CHARGE_EDIT, _vm.permissions) ? _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "4"
    }
  }, [_vm.isDeliveryChargeSubmitting ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": _vm.submitDeliveryCharge
    }
  }, [_vm._v(" Submit ")])]], 2) : _vm._e()], 1)], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }